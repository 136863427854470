import { variantProvider, React, AppForms } from '@/app'
import { AnyFunction, deepEqual, onMount, useForm } from '@codeleap/common'
import { View, Text, TextInput, ActionIcon, SelectForm } from '@/components'
import { BaseForm, FormComponentProps } from './BaseForm'
import { APIClient } from '@/services'
import { PossibleReviewer, ScientificTag } from '@/types'
import { reviewerInitialState, usePublicationForm } from './usePublicationForm'

const Form = (
  props: FormComponentProps<Partial<PossibleReviewer>> & {
    scientificTags: ScientificTag[]
    disabled?: boolean
    saveEnabled: AnyFunction
  },
) => {
  const { isFirst, value, onChangeValue, deleteForm, scientificTags, disabled = false, saveEnabled, index } = props
  const form = useForm(AppForms.reviewerForm, { initialState: value })
  const showDeleteIcon = !(isFirst || disabled)

  onMount(() => saveEnabled?.(!!deepEqual(reviewerInitialState, form.values) || form.isValid))

  function handleForm(field: 'full_name' | 'organisation' | 'email' | 'tags') {
    const formField = form.register(field)
    return {
      ...formField,
      disabled,
      onBlur: () => {
        const newValues = form.values as Partial<PossibleReviewer>
        if (!deepEqual(newValues, value)) {
          onChangeValue(newValues)
          saveEnabled?.(form.isValid)
        }
      },
    }
  }

  return (
    <View style={styles.wrapper} id={`suggestedReviewerForm_${index}`}>
      <View variants={['row', 'alignCenter', 'justifySpaceBetween']}>
        <Text text={'Reviewer'} variants={['h5', 'color:neutral8']} />
        {showDeleteIcon && (
          <ActionIcon
            icon='trash'
            variants={['destructive:minimal', 'normalize']}
            debugName='Remove reviewer form'
            onPress={deleteForm}
          />
        )}
      </View>

      <View style={styles.grid}>
        <TextInput {...handleForm('full_name')}
          id={`full_name_input_${index}`} />
        <TextInput {...handleForm('organisation')}
          id={`organisation_input_${index}`} />
        <TextInput {...handleForm('email')}
          id={`email_input_${index}`} />
        <SelectForm
          {...handleForm('tags')}
          id={`tags_input_${index}`}
          value={(form?.values?.tags as Array<number>)?.map((tag) => scientificTags?.find((t) => t.value === tag))}
          options={scientificTags}
          leftIcon={{ name: 'flask-conical' }}
          disabled={disabled}
          styles={{
            leftIconTouchableWrapper: styles.leftIconTouchableWrapper,
          }}
          fieldName={`reviewerRegion:${index}`}
        />
      </View>
    </View>
  )
}

export const SuggestReviewerForm = () => {
  const { publication, setSuggestReviewerForms, suggestReviewerForms, setSuggestReviewersIsValid, isSaved_in_drafts } = usePublicationForm()
  const { delete: deleteReviewer } = APIClient.Publications.possibleReviewersManager.useDelete()
  const { scientificTags, profile } = APIClient.Session.useSession()

  function onDelete(item: PossibleReviewer) {
    if (item.id) deleteReviewer(item)
  }

  return (
    <BaseForm<Partial<PossibleReviewer>>
      forms={suggestReviewerForms}
      setForms={setSuggestReviewerForms}
      initialState={reviewerInitialState}
      onDelete={onDelete}
      showAddMoreButton={isSaved_in_drafts}
      formComponent={(props) => (
        <Form
          scientificTags={scientificTags}
          saveEnabled={setSuggestReviewersIsValid}
          disabled={(profile?.current_role === 'editor' || profile?.current_role === 'publisher') || !isSaved_in_drafts}
          {...props}
        />
      )}
      max={publication?.reviewers_quantity}
    />
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    grid: {
      display: 'grid',
      // prevent grid from overflowing horizontally when large texts are typed on the text input
      gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
      columnGap: theme.spacing.value(5),
      rowGap: theme.spacing.value(3),
      [theme.media.down('small')]: {
        // prevent grid from overflowing horizontally when large texts are typed on the text input
        gridTemplateColumns: 'minmax(0, 1fr)',
      },
    },
    wrapper: {
      ...theme.presets.column,
      ...theme.spacing.gap(3),
      ...theme.spacing.padding(3),
      ...theme.border.neutral5({ width: 1 }),
      borderRadius: theme.borderRadius.large,
    },
    leftIconTouchableWrapper: {
      ...theme.spacing.marginRight(1),
    },
  }),
  true,
)
